import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import DateRangePicker from "../DatePicker/DateRangePicker";
import DayPicker from "../DatePicker/DayPicker";

import {
  endOfToday,
  endOfWeek,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYesterday,
} from "date-fns";
import TransactionsItems from "./TransactionsItems";

export interface TransactionData {
  account_id: number;
  transaction_time: string;
  receive_amount: number;
  pay_amount: number;
  payment_provider: string;
  transactions_type: string;
  id: string;
  source_name: string;
  transaction_status: string;
}

export default function Transactions() {
  const todayStart = startOfToday();
  const todayEnd = endOfToday();
  const yesterdayStart = startOfYesterday();
  const yesterdayEnd = endOfYesterday();
  const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const monthStart = startOfMonth(new Date());
  const [transactionData, setTransactionData] = useState<TransactionData[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(monthStart);
  const [endDate, setEndDate] = useState<Date | null>(todayEnd);
  const [offerType, setOfferType] = useState<string>("all");
  const [dateValue, setDateValue] = useState<string>("month");

  async function getTransactions(type: string = "all") {
    setLoading(true);

    const session_token = Cookies.get("c8_session_token");
    try {
      const url = `${
        process.env.API_URL
      }/transactions?user_session=${session_token}${
        type !== "all" ? `&transaction_type=${type}` : ""
      }${
        startDate && endDate
          ? `&transaction_time_from=${startDate.toISOString()}&transaction_time_to=${endDate.toISOString()}`
          : ""
      }`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setTransactionData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (startDate && endDate) {
      getTransactions(offerType);
    }
  }, [offerType, startDate, endDate]);

  if (loading)
    return (
      <p className="text-[20px] text-white font-[700] font-anton">Loading...</p>
    );

  function handleDateChange(value: string) {
    setDateValue(value);

    switch (value) {
      case "today":
        setStartDate(todayStart);
        setEndDate(todayEnd);
        break;
      case "yesterday":
        setStartDate(yesterdayStart);
        setEndDate(yesterdayEnd);
        break;
      case "week":
        setStartDate(weekStart);
        setEndDate(todayEnd);
        break;
      case "month":
        setStartDate(monthStart);
        setEndDate(todayEnd);
        break;
      case "range":
        return;
      case "day":
        return;
    }
  }

  return (
    <div className="w-[100%]">
      <div className="flex flex-row gap-2 items-center font-chakra ml-2">
        <select
          onChange={(e) => setOfferType(e.target.value)}
          value={offerType}
          className="w-[160px] h-[40px] drop-down-font"
        >
          <option className="drop-down-font" value="shop">
            Shop Transactions
          </option>
          <option className="drop-down-font" value="game">
            Game Transactions
          </option>
          <option className="drop-down-font" value="all">
            All Types
          </option>
        </select>

        <select
          value={dateValue}
          className="w-[160px] h-[40px] drop-down-font"
          onChange={(e) => handleDateChange(e.target.value)}
        >
          <option className="drop-down-font" value="today">
            Today
          </option>
          <option className="drop-down-font" value="yesterday">
            Yesterday
          </option>
          <option className="drop-down-font" value="week">
            Week
          </option>
          <option className="drop-down-font" value="month">
            Month
          </option>
          <option className="drop-down-font" value="range">
            Date Range
          </option>
          <option className="drop-down-font" value="day">
            Exact Date
          </option>
        </select>
        <div className="hidden md:flex">
          {dateValue === "range" && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}
          {dateValue === "day" && (
            <DayPicker
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              className="h-[40px] drop-down-font"
            />
          )}
        </div>
      </div>
      <div className="flex flex-row justify-start md:hidden items-center font-chakra mt-1 ml-2">
        <div>
          {dateValue === "range" && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          )}
        </div>
        <div>
          {dateValue === "day" && (
            <DayPicker
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              className={"h-[40px] drop-down-font"}
            />
          )}
        </div>
      </div>
      <TransactionsItems transactionData={transactionData} itemsPerPage={10} />
    </div>
  );
}
