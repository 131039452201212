import { ChangeEvent, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { TransactionData } from "./Transactions";
import { Link, useNavigate } from "react-router-dom";
import GotIt from "../Modals/GotIt";

const isHardTransaction = (t: TransactionData): boolean =>
  t.transactions_type === "shop" &&
  (t.payment_provider === "Google Pay" || t.payment_provider === "Razorpay");

const isGameTransaction = (t: TransactionData): boolean => {
  return t.transactions_type === "game";
};

export default function TransactionsItems({ transactionData, itemsPerPage }) {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPageState, setItemsPerPageState] = useState(itemsPerPage);
  const [open, setOpen] = useState(false);
  const [selectDetail, setSelectDetail] = useState<TransactionData | null>(
    null
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  const endOffset = itemOffset + itemsPerPageState;
  const currentTransactions = transactionData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(transactionData.length / itemsPerPageState);

  function handlePageClick(e: { selected: number }) {
    const newOffset = (e.selected * itemsPerPageState) % transactionData.length;
    setItemOffset(newOffset);
  }

  function handleItemsPerPageChange(e: ChangeEvent<HTMLSelectElement>) {
    setItemsPerPageState(e.target.value);
    setItemOffset(0);
  }

  function closeModal() {
    setSelectDetail(null);
  }

  function toggleOpen(transaction: TransactionData) {
    setSelectDetail(transaction);
    setOpen((open) => !open);
  }

  function handleSendId(id: string | number) {
    navigate(id ? `/refundpolicy/${id}` : `/refundpolicy`);
  }

  useEffect(() => {
    function resizePage() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", resizePage);
  }, []);

  return (
    <>
      {currentTransactions.length > 0 ? (
        currentTransactions.map((transaction: TransactionData, index: any) => (
          <div
            key={index}
            className="w-[100%] mt-[20px] p-2 rounded-sm bg-mainB"
          >
            {isHardTransaction(transaction) ? (
              <div onClick={() => toggleOpen(transaction)}>
                <div className="flex flex-row justify-between">
                  <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#A296B2]">
                    {transaction.id}
                  </p>
                  <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#A296B2]">
                    {new Date(transaction.transaction_time).toLocaleString()}
                  </p>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-3 w-full">
                  <div className="flex flex-col items-start w-full">
                    <p className="text-white text-[14px] font-[700] font-chakra bold">
                      Bought:
                    </p>
                    <p className="text-white text-[14px] font-[700] font-chakra bold">
                      Cost:
                    </p>
                    <p className="text-white text-[14px] font-[700] font-chakra bold">
                      Status:
                    </p>
                    <p className="text-white text-[14px] font-[700] font-chakra bold">
                      Payment Provider:
                    </p>
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <div className="flex flex-row items-center">
                      <img
                        className="w-[17px] h-[10px] mr-1"
                        src="../img/public/rubin_sm.png"
                        alt="Currency"
                      />
                      <p className="text-white text-[14px] font-[400] font-chakra">
                        {transaction.receive_amount}
                      </p>
                    </div>
                    <div className="text-white text-[14px] font-[400] font-chakra">
                      ₹{transaction.pay_amount}
                    </div>
                    <p className="text-white text-[12px] font-[400] font-chakra">
                      {transaction.transaction_status}
                    </p>
                    <p className="text-white text-[14px] font-[400] font-chakra">
                      {transaction.payment_provider}
                    </p>
                  </div>
                </div>

                {open && selectDetail && (
                  <GotIt
                    isOpen={open}
                    onRequestClose={closeModal}
                    contentLabel="Details"
                    children={
                      <div className="p-2">
                        <p className="text-white font-anton text-center">
                          Transaction Details
                        </p>
                        <div className="flex flex-row justify-between">
                          <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#68537D]">
                            {selectDetail.id}
                          </p>
                          <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#68537D]">
                            {new Date(
                              selectDetail.transaction_time
                            ).toLocaleString()}
                          </p>
                        </div>
                        <div className="grid grid-cols-2 lg:grid-cols-3">
                          <div className="flex flex-col items-start">
                            <p className="text-white text-[14px] font-[700] font-chakra bold">
                              Purchased:
                            </p>
                            <p className="text-white text-[14px] font-[700] font-chakra bold">
                              Cost:
                            </p>
                            <p className="text-white text-[14px] font-[700] font-chakra bold">
                              Status:
                            </p>
                            <p className="text-white text-[14px] font-[700] font-chakra bold">
                              Payment Provider:
                            </p>
                          </div>
                          <div className="flex flex-col items-start">
                            <div className="flex flex-row items-center">
                              <img
                                className="w-[17px] h-[10px] mr-1"
                                src="../img/public/rubin_sm.png"
                                alt="Currency"
                              />
                              <p className="text-white text-[14px] font-[400] font-chakra">
                                {selectDetail.receive_amount}
                              </p>
                            </div>
                            <div className="text-white text-[14px] font-[400] font-chakra">
                              ₹{selectDetail.pay_amount}
                            </div>
                            <p className="text-white text-[14px] font-[400] font-chakra">
                              {selectDetail.transaction_status}
                            </p>
                            <p className="text-white text-[14px] font-[400] font-chakra">
                              {selectDetail.payment_provider}
                            </p>
                          </div>
                        </div>
                        <div className="w-full flex flex-col items-center">
                          <Link
                            className="text-[white] mt-auto text-[8px] underline"
                            to="#"
                            onClick={() => handleSendId(selectDetail.id)}
                          >
                            Read our policy
                          </Link>
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
            ) : (
              <div>
                <div className="flex flex-row justify-between">
                  <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#A296B2]">
                    {transaction.id}
                  </p>
                  <p className="text-[10px] leading-[18px] font-[400] font-chakra text-[#A296B2]">
                    {new Date(transaction.transaction_time).toLocaleString()}
                  </p>
                </div>
                {isGameTransaction(transaction) ? (
                  <div className="grid grid-cols-3">
                    <div className="flex flex-col items-start">
                      <p className="text-white text-[14px] font-[700] font-chakra bold">
                        {transaction.receive_amount > 0 ? "Received" : "Payed"}
                      </p>
                      <p className="text-white text-[14px] font-[700] font-chakra bold">
                        Game:
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="flex flex-row items-center">
                        <img
                          className="w-[17px] h-[10px] mr-1"
                          src="../img/public/coin_sm.png"
                          alt="Currency"
                        />
                        <p className="text-white text-[14px] font-[400] font-chakra">
                          {transaction.receive_amount > 0
                            ? transaction.receive_amount
                            : transaction.pay_amount}
                        </p>
                      </div>
                      <div className="text-white text-[14px] font-[400] font-chakra">
                        {transaction.source_name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-3">
                    <div className="flex flex-col items-start">
                      <p className="text-white text-[14px] font-[700] font-chakra bold">
                        Bought:
                      </p>
                      <p className="text-white text-[14px] font-[700] font-chakra bold">
                        Cost:
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <div className="flex flex-row items-center">
                        <img
                          className="w-[17px] h-[10px] mr-1"
                          src="../img/public/coin_sm.png"
                          alt="Currency"
                        />
                        <p className="text-white text-[14px] font-[400] font-chakra">
                          {transaction.receive_amount}
                        </p>
                      </div>
                      <div className="text-white text-[14px] font-[400] font-chakra">
                        <img
                          src="../img/public/rubin_sm.png"
                          alt="Currency"
                          className="inline w-[17px] h-[10px] mr-1"
                        />
                        {transaction.pay_amount}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      ) : (
        <p className="mt-[25px]">No Transactions</p>
      )}
      <div className="flex items-center justify-center flex-row gap-2 mt-4">
        <select
          className="w-[80px] h-[35px] lg:h-[40px] lg:w-[127px] text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
          value={itemsPerPageState}
          onChange={handleItemsPerPageChange}
        >
          <option value={10}>Show 10</option>
          <option value={25}>Show 25</option>
          <option value={50}>Show 50</option>
        </select>
        <ReactPaginate
          breakLabel="..."
          nextLabel="→"
          onPageChange={handlePageClick}
          pageRangeDisplayed={isMobile ? 2 : 3}
          marginPagesDisplayed={isMobile ? 0 : 10}
          pageCount={pageCount}
          previousLabel="←"
          containerClassName="flex items-center justify-center gap-2"
          pageClassName="w-[35px] h-[35px] lg:w-[40px] lg:h-[40px] bg-white text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
          pageLinkClassName="text-sm text-gray-700"
          activeClassName="!bg-lime-bg text-main"
          previousClassName="w-[35px] h-[35px] lg:w-[40px] lg:h-[40px] flex items-center justify-center bg-white"
          previousLinkClassName="text-lg text-gray-700"
          nextClassName="w-[35px] h-[35px] lg:w-[40px] lg:h-[40px] flex items-center justify-center bg-white"
          nextLinkClassName="text-lg text-gray-700"
          breakClassName="w-[35px] h-[35px] lg:w-[40px] lg:h-[40px] flex items-center justify-center"
          breakLinkClassName="text-sm text-gray-700"
        />
      </div>
    </>
  );
}
